











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import JobsFilterData from '@/misc/JobsFilterData';
import { namespace } from 'vuex-class';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import User from '@/models/User';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import { jobStoreMutations } from '@/stores/job.store';
import { Permission } from '@/misc/enums/permission.enum';

const JobStore = namespace('job');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    JobCalendarLegendComponent: () => import(
        /* webpackChunkName: "JobCalendarLegendComponent" */
        '@/components/job/JobCalendarLegend.component.vue'),
    CalendarViewWeek: () => import(
        '@/components/job/calendar-views/CalendarViewWeek.component.vue'),
    CalendarViewDay: () => import(
        '@/components/job/calendar-views/CalendarViewDay.component.vue'),
    AdvDatePicker: () => import(
        '@/components/shared/AdvDatePicker.component.vue'),
  },
})
export default class JobCalendarComponent extends Vue {

  /**
   *  The start date of the current time period
   */
  public startDate: Moment | null = null;
  /**
   *  The end date of the current time period
   */
  public endDate: Moment | null = null;
  public displayDay: Moment = moment();
  /**
   * Current active calendar type
   */
  public currentCalendarType: 'week' | 'day' = 'week';

  @Prop()
  public initialCalendarType!: 'week' | 'day';
  /**
   * Prop to be able to group the jobs by customers or employees
   */
  @Prop({ default: 'none' })
  public groupBy!: 'none' | 'customer' | 'employee';

  @Prop()
  private initialFocus!: string;

  @Prop()
  private selectedStatus!: string[];

  /**
   *  Value to focus on
   */
  private focus: string | null = null;

  @JobStore.Mutation(jobStoreMutations.CLEAR_JOBS)
  public clearJobs!: () => void;

  @JobStore.Getter('jobs')
  private _jobs!: Job[];

  get jobs(): Job[] {
    return this.isLoading ? [] : this._jobs;
  }

  @JobStore.Getter('loading')
  private _isLoading!: boolean;

  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   *  Getter which returns the _jobs as CalendarEvents with all needed data
   */
  get calendarEvents(): Array<{ job: Job, userData: JobUserDataInterface[] }> {
    // if there are any jobs
    const calendarEvents: Array<{ job: Job, userData: JobUserDataInterface[] }> = [];
    // go through all jobs,
    // get all possible events and push it to our event array
    for (const job of this.jobs.filter((filterJob) => this.selectedStatus.length === 0 || this.selectedStatus.includes(filterJob.status))) {
      calendarEvents.push(this.jobEventFactory(job));
    }
    return calendarEvents;
  }

  /**
   * Returns the possible views of the calendar. Current are week and day
   * @private
   */
  private get calendarTypes(): Array<{ text: string, value: 'week' | 'day' }> {
    return [{
      text: this.$t('GENERAL.DAY').toString(),
      value: 'day',
    }, {
      text: this.$t('GENERAL.WEEK').toString(),
      value: 'week',
    }];
  }

  public created() {
    // set initial calendar view
    if (this.initialCalendarType) {
      this.currentCalendarType = this.calendarTypes.find((type) => type.value === this.initialCalendarType)!.value;
    }

    // set initial focus value
    if (this.initialFocus) {
      this.focus = this.initialFocus;
    }
    this.setDaysFromDate();
  }

  /**
   * Handles click on day entry in monthly or weekly view. Basically it just changes the calendar to daily view
   * of the clicked day.
   * @param date
   */
  public onDateClick(date: Moment) {
    this.focus = date.toISOString();
    this.displayDay = date.clone();
    this.currentCalendarType = this.calendarTypes.find((item) => item.value === 'day')!.value;
    console.log(this.currentCalendarType);
  }

  /**
   *  Creates a calendar event (calendar entry) based on a job. This entry contains the necessary
   *  CalendarEvent data as well as job and userData data.
   */
  public jobEventFactory(job: Job): { job: Job, userData: JobUserDataInterface[] } {
    // create user data information iv available
    const userData: JobUserDataInterface[] = (
        job.cleanTime?.plannedUsers as User[] ??
        job.workSessions.map((workSession) => workSession.user)
    ).filter((user) => !!user).map((user) => this.createDataForUserInitials(job, user));

    // checks if the dateEnd is given (can be null on infinite recurrences), if so sets the dateEnd as the dateStart + 1
    if (job.cleanTime && !job.cleanTime.dateEnd) {
      job.cleanTime.dateEnd = moment(job.cleanTime.date).add(1, 'day').toISOString();
    }

    // Return created Calendar Events
    return {
      job,
      userData,
    };
  }

  private setDaysFromDate(date: Moment = this.displayDay) {
    this.clearJobs();
    this.startDate = moment(date).startOf(this.currentCalendarType);
    this.endDate = moment(date).endOf(this.currentCalendarType);
    this.onTimePeriodChange();
  }

  @Watch('focus')
  private onFocusChange() {
    this.$emit('calendar-focus-change', this.focus);
  }

  /**
   *  Emit an event if current calendar type changes
   */
  @Watch('currentCalendarType')
  private onCurrentCalendarTypeChange() {
    this.setDaysFromDate();
    this.$emit('calendar-type-change', this.currentCalendarType);
  }

  /**
   * Emits an event to notify parent component about time period changes
   */
  private onTimePeriodChange() {
    // create moment range
    const diff = this.endDate!.diff(this.startDate!, 'days');
    const dateRange = [this.startDate!];
    if (diff > 0) {
      for (let i = 1; i <= diff; i++) {
        dateRange.push(this.startDate!.clone().add(i, 'days'));
      }
    }
    const filterData: Partial<JobsFilterData> = {
      dates: dateRange.map((item) => item.toISOString()),
    };
    this.$emit('time-period-change', filterData);
  }

  /**
   *  Creates the user initials status data (small badge next to the big initial badge)
   *  for a user. This status data depends on the users work session from for this job.
   */
  private createDataForUserInitials(job: Job, user: User): JobUserDataInterface {
    const config = new UserInitialsConfig();
    config.showFullName = false;

    // identify status
    // if a work session with this user can be found, the job was started
    const workSession = job.workSessions.find((item) => {
      // If item is User Object then check id's
      if (item.user instanceof User) {
        return (item.user as User).id === user.id;
      }

      // Check as if user is an id
      return item.user === user.id;
    });

    let isFinished = false;
    let isCancelled = false;
    if (workSession) {
      isCancelled = !!workSession.canceledByReason;
      isFinished = !!workSession.endTime;
    }

    // create right statusBadgeData config according the current status
    if (isCancelled) {
      config.statusBadgeData = {
        icon: 'mdi-close',
        backgroundColor: 'status-cancelled',
      };
      config.isCentered = false;
    } else if (isFinished) {
      // finished
      config.statusBadgeData = {
        icon: 'mdi-check',
        backgroundColor: 'status-done',
      };
    } else if (workSession) {
      // started
      config.statusBadgeData = {
        icon: 'mdi-dots-horizontal',
        backgroundColor: 'status-in-progress',
      };
    }
    return {
      user,
      config,
      workSession,
    };
  }

  private showEventDetails(event: any) {
    this.$emit('job-info-change', event);
  }

  private setDisplayDate(e: Moment) {
    this.displayDay = e;
    this.setDaysFromDate();
  }
}
